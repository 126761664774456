/** @jsx jsx */
import { jsx, Styled, AspectRatio } from 'theme-ui';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import headerLeadershipSvg from '../img/headers/header-leadership.svg';
// import ceoImg from '../img/catherine-lunardi.png';
import ceoSquareImg from '../img/catherine-lunardi-square.png';

import profileNeilImg from '../img/profiles/profile-neil.png';
import profileJovanaImg from '../img/profiles/profile-jovana.png';
// import profilePierreImg from '../img/profiles/profile-pierre.png';
// import profileZellImg from '../img/profiles/profile-zell.png';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import AspectRatioImg from '../components/AspectRatioImg';

const profileImageMap = {
  neil: profileNeilImg,
  jovana: profileJovanaImg,
  // pierre: profilePierreImg,
  // zell: profileZellImg,
};

const Profile = ({ name, imageKey, title, linkedInUrl }) => (
  <div sx={{ textAlign: 'center' }}>
    <div>
      <AspectRatio
        ratio={1}
        sx={{
          borderRadius: '9999px',
          overflow: 'hidden',
        }}
      >
        <img src={profileImageMap[imageKey]} alt={name} />
      </AspectRatio>
    </div>
    <p sx={{ mt: 4, fontWeight: 'semibold' }}>{name}</p>
    <p sx={{ mt: 2, fontSize: '14px', lineHeight: 1.5 }}>{title}</p>
    <div>
      <a
        href={linkedInUrl}
        target="_blank"
        rel="noreferrer noopener"
        sx={{
          color: 'text',
          fontSize: '12px',
          textDecoration: 'underline',
          '&:hover': {
            color: 'secondary',
          },
        }}
      >
        LinkedIn Profile
      </a>
    </div>
  </div>
);

export const LeadershipPageTemplate = ({ get }) => (
  <section>
    <Container>
      <AspectRatioImg src={headerLeadershipSvg} alt="" ratio={912 / 256} />
      <Styled.h1 sx={{ mt: 13 }}>{get('title')}</Styled.h1>
      <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
    </Container>
    <Container sx={{ mt: 11 }}>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr'],
          gap: 7,
        }}
      >
        <div>
          <Styled.h3>
            {get('ceo.name')}
            <br />
            {get('ceo.title')}
          </Styled.h3>
          <MarkdownContent sx={{ my: 8 }}>{get('ceo.text')}</MarkdownContent>
        </div>
        <div>
          <img src={ceoSquareImg} alt={get('ceo.name')} />
          <p sx={{ fontSize: '12px' }}>{get('ceo.image.credits')}</p>
          <p sx={{ fontSize: '18px', fontWeight: 'semibold' }}>
            {get('ceo.name')}
          </p>
          <p sx={{ fontSize: '14px' }}>{get('ceo.image.caption')}</p>
          <div>
            <a
              href={get('ceo.linkedInUrl')}
              target="_blank"
              rel="noreferrer noopener"
              sx={{
                color: 'text',
                fontSize: '14px',
                textDecoration: 'underline',
                '&:hover': {
                  color: 'secondary',
                },
              }}
            >
              LinkedIn Profile
            </a>
          </div>
        </div>
      </div>
      <Styled.h2 sx={{ mt: 13 }}>{get('team.title')}</Styled.h2>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
          gap: 11,
          mt: 8,
        }}
      >
        {Array.from({
          ...get('team.profiles', []),
          length: 4,
        }).map((profile, i) =>
          profile ? <Profile key={i} {...profile} /> : <div />
        )}
      </div>
    </Container>
  </section>
);

const LeadershipPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      page="leadership"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <LeadershipPageTemplate get={createGet(frontmatter)} />
    </Layout>
  );
};

LeadershipPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default LeadershipPage;

export const pageQuery = graphql`
  query LeadershipPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        text
        ceo {
          name
          title
          linkedInUrl
          image {
            caption
            credits
          }
          text
        }
        team {
          title
          profiles {
            name
            title
            linkedInUrl
            imageKey
          }
        }
      }
    }
  }
`;
